<template>
  <div class="app-container">
    <div class="main-body">
      <el-row class="call-title">
        <el-col :span="24">
          <i class="lc-tms-iconyunweiguanli icon-cls icon-cls-green" />
          <span class="title">系统日志</span>
        </el-col>
      </el-row>
      <div ref="searchBar" class="form-cls">
        <!-- feat: 搜索栏过多收起 -->
        <!-- <search-bar
          @handleSearch="queryList"
          @heightChange="_getTableHeight()"
        > -->
        <el-form @heightChange="_getTableHeight()">
          <el-row>
            <el-col :span="12">
              <el-form-item label="操作日期:">
                <el-date-picker
                  v-model="dateRange"
                  type="daterange"
                  size="small"
                  @keyup.enter.native="queryList"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item label-width="0px">
                <el-button plain key="query" size="small" type="primary" icon="el-icon-search" @click="queryList"> 查询 </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- </search-bar> -->
      </div>
      <div class="main-body">
        <vxe-toolbar ref="xToolbar" custom style="margin-left: 10px; padding-right: 10px">
          <template #buttons>
            <el-button plain key="show" size="small" type="primary" icon="el-icon-download" @click="exportLog"> 导出 </el-button>
          </template>
          <template #tools>
            <vxe-pager
              align="left"
              placement="top"
              :total="total"
              :current-page.sync="queryParams.current"
              border
              :page-sizes="[100, 120, 150]"
              size="small"
              :page-size.sync="queryParams.szie"
              :layouts="['PrevJump', 'PrevPage', 'Jump', 'NextPage', 'NextJump', 'Sizes', 'Total']"
              @page-change="queryList('0')"
            />
          </template>
        </vxe-toolbar>
      </div>
      <el-row class="form-cls button-cls">
        <el-col :span="24">
          <vxe-table
            id="dataListTable"
            ref="table"
            v-loading="listLoading"
            class="sortable-column-demo"
            :export-config="{}"
            :data="dataList"
            fit
            show-footer
            :height="_tableHeight"
            element-loading-text="加载中"
            border
            stripe
            column-key
            resizable
            highlight-current-row
            :resizable-config="{ minWidth: 50 }"
            :checkbox-config="{ trigger: 'row', highlight: true }"
            :custom-config="{ storage: true }"
            :scroll-y="{ gt: -1 }"
            show-overflow="tooltip"
            show-footer-overflow="tooltip"
            show-header-overflow="tooltip"
            tooltip-effect="dark"
            size="small"
            style="width: 100%"
          >
            <!-- <vxe-table-column
              type="checkbox"
              width="50"
              align="center"
              fixed="left"
            /> -->
            <vxe-table-column type="seq" title="序号" width="50" align="center" fixed="left" />
            <vxe-table-column field="username" title="操作人" sortable align="center" width="200" show-overflow-tooltip />
            <vxe-table-column field="created" title="操作时间" sortable align="center" width="250" show-overflow-tooltip />
            <vxe-table-column field="method" title="操作事件" align="center" width="300" />
            <vxe-table-column field="description" align="center" title="描述" sortable />
          </vxe-table>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { queryOperationList, exportOprerationLog } from '@/api/log'
import { getToken } from '@/utils/auth'
import CalTableHeight from '@/mixins/calTableHeight'
import Sortable from 'sortablejs'

export default {
  mixins: [CalTableHeight],
  data: function () {
    return {
      total: 0,
      dateRange: [],
      dataList: [],
      summary: {},
      listLoading: false,
      // 查询条件
      queryParams: {
        startCreated: '',
        endCreated: '',
        current: 1,
        size: 100
      }
    }
  },
  computed: {
    headers() {
      return {
        token: getToken()
      }
    }
  },
  created() {
    this.$nextTick(() => {
      // 手动将表格和工具栏进行关联,否则列配置将失效
      this.$refs.table.connect(this.$refs.xToolbar)
    })
    this.queryList()
  },
  activated() {
    // this.queryList()
  },
  mounted() {
    this.orderByColumn()
    // feat: fixed table height
    // table高度初始化，部分模块高度不固定需要等待dom渲染结束再计算
    this.$nextTick(() => {
      this._getTableHeight()
    })
  },
  methods: {
    // feat: fixed table height
    // 返回除table高度外的额外总高度
    _getExtraHeight() {
      return 185 + this.$refs.searchBar.offsetHeight
    },

    // 导出
    exportLog() {
      exportOprerationLog().then(response => {
        if (!response) {
          return
        }
        const url = window.URL.createObjectURL(new Blob([response]))
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', '操作日志.xlsx')
        document.body.appendChild(link)
        link.click()
      })
    },
    // 列拖拽
    orderByColumn() {
      this.$nextTick(() => {
        const ths = this
        const xTable = this.$refs.table
        this.sortable1 = Sortable.create(xTable.$el.querySelector('.body--wrapper>.vxe-table--header .vxe-header--row'), {
          handle: '.vxe-header--column:not(.col--fixed)',
          animation: 150,
          onEnd: ({ item, newIndex, oldIndex }) => {
            const { fullColumn, tableColumn } = xTable.getTableColumn()
            const targetThElem = item
            const wrapperElem = targetThElem.parentNode
            const newColumn = fullColumn[newIndex]
            if (newColumn.fixed) {
              // 错误的移动
              if (newIndex > oldIndex) {
                wrapperElem.insertBefore(targetThElem, wrapperElem.children[oldIndex])
              } else {
                wrapperElem.insertBefore(wrapperElem.children[oldIndex], targetThElem)
              }
              return this.$XModal.message({
                message: '固定列不允许拖动！',
                status: 'error'
              })
            }
            // 转换真实索引
            const oldColumnIndex = xTable.getColumnIndex(tableColumn[oldIndex])
            const newColumnIndex = xTable.getColumnIndex(tableColumn[newIndex])
            // 移动到目标列
            const currRow = fullColumn.splice(oldColumnIndex, 1)[0]
            fullColumn.splice(newColumnIndex, 0, currRow)
            const order = []
            fullColumn.forEach(item => {
              if (item.type === 'checkbox') {
                order.push('checkbox')
              } else {
                order.push(item.title)
              }
            })
            localStorage.setItem('VXE_TABLE_OPERATION_LOG_COLUMN_SORT', order.join('|'))
            xTable.loadColumn(fullColumn)
          },
          // 添加列排序会特别影响速度，因为取消了列的虚拟表格
          store: {
            get(sortable) {
              const order = localStorage.getItem('VXE_TABLE_OPERATION_LOG_COLUMN_SORT')
              const xTable = ths.$refs.table
              const { fullColumn } = xTable.getTableColumn()
              const ords = order ? order.split('|') : []
              const tabs = []
              if (ords.length > 0) {
                ords.forEach(i => {
                  fullColumn.forEach(item => {
                    if (i === item.title || i === item.type) {
                      tabs.push(item)
                    }
                  })
                })
                fullColumn.forEach((item, idx) => {
                  let flag = false
                  ords.forEach(i => {
                    if (i === item.title || i === item.type) {
                      flag = true
                    }
                  })
                  if (!flag) {
                    tabs.splice(idx, 0, item)
                  }
                })
                xTable.loadColumn(tabs)
              }
            }
          }
        })
      })
    },
    // 行选中
    selectRows() {},
    copyText(value, type) {
      // 用于格式化日期，然后将日期作为内容复制到剪切板上进行复制
      if (type === 'dateFormater') {
        value = this.dateFormat(value)
      }
      XEClipboard.copy(value)
      this.$message.warning('已复制到剪切板')
    },
    // 查询
    queryList(flag) {
      this.listLoading = true
      if (this.dateRange.length > 0) {
        this.queryParams.startCreated = this.dateRange[0]
        this.queryParams.endCreated = this.dateRange[1]
      }
      queryOperationList(this.queryParams).then(res => {
        if (res.code === 200) {
          this.dataList = res.data.records
          this.total = res.data.total
        }
        this.listLoading = false
      })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.input-cls {
  width: 260px;
  font-size: 14px;
  // feat: 搜索栏过多收起
  margin-right: 10px;
}
.icon-cls-green {
  color: #67c23a;
}
.dialog-row {
  margin-top: 10px;
}
</style>

<style rel="stylesheet/scss" lang="scss">
.validFieldClass .el-input__inner {
  border-color: #f56c6c;
}
.el-pagination {
  float: right;
}
.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
  background-color: #dfecfb;
}
.vxe-table--footer .col-blue {
  background: #f2f5f0;
}
/*单号复制样式 开始*/
.copy-content {
  right: 0.1em;
  top: 0.1em;
  float: right !important;
  position: absolute !important;
}
.copy-content:hover {
  background-color: #1482f0;
  float: right !important;
  height: 1.8em;
  width: 1.8em;
}
.write-color {
  border-color: #f0f0f0;
}
/*单号复制样式 结束*/
.input-width-6 {
  width: 60% !important;
}
.input-width-7 {
  width: 70% !important;
}
.input-width-8 {
  width: 80% !important;
}
</style>
